import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Layout from "../comps/reusable/Layout";
import { useTranslation } from "gatsby-plugin-react-i18next";
import TextField from "@mui/material/TextField";
import { buttonRouge, getFlex, palette } from "../styles/styles";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Asterisk } from "@styled-icons/fa-solid";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { Backdrop, Select, Stack, MenuItem } from "@mui/material";
import { countries, countryToFlag } from "../utils/utils";
// import HubspotForm from 'react-hubspot-form';

const Contact = (props) => {
  const { className } = props;
  const { t } = useTranslation("contact");

  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    companyName: "",
    phone: "",
    phoneCode: 52,
    helpOption: "",
    body: "",
  });

  const options = [
    { value: t("helpOption1"), label: t("helpOption1") },
    { value: t("helpOption2"), label: t("helpOption2") },
    { value: t("helpOption3"), label: t("helpOption3") },
    { value: t("helpOption4"), label: t("helpOption4") },
    { value: t("helpOption5"), label: t("helpOption5") },
  ];

  const onChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    if (name === 'body') {
      setCounter(value.length);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    toggleBackdrop();
    const data = {...formData};
    const payload = {
      email: ["alejandro.bautista@lapieza.io", "dania.ruiz@lapieza.io"],
      data,
      templateId: "fd7f8ad5239b4e7097bcad8591999b65",
    };

    sendEmail(payload)
      .then(() => {
        toggleBackdrop();
        setFormData({
          name: "",
          email: "",
          companyName: "",
          phone: "",
          phoneCode: 52,
          helpOption: "",
          body: "",
        });
        setCounter(0);
        NotificationManager.success(t("messageSent"), null, 3000);
      })
      .catch(() => {
        toggleBackdrop();
        NotificationManager.error(t("messageFailed"), null, 3000);
      });
  };

  const sendEmail = async (data = {}) => {
    await axios.request({
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      url: "https://us-central1-pieza-development.cloudfunctions.net/sendMailCandidateInvitation",
      method: "POST",
      data,
      crossDomain: true,
    });
  };

  const toggleBackdrop = () => {
    setOpen((prevState) => !prevState);
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Layout>
      <section className={className}>
        <NotificationContainer />
        <div className="contact">
          <h2>{t("contactTitle")}</h2>
          <p>{t("contactDesc")}</p>
          <form className="form" onSubmit={onSubmit}>
            <div className="field">
              <label>
                {t("name")} <Asterisk className="asterisk" />
              </label>
              <TextField
                required
                placeholder="Pol Morral"
                variant="standard"
                fullWidth
                name="name"
                value={formData.name}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <label>
                {t("email")} <Asterisk className="asterisk" />
              </label>
              <TextField
                required
                placeholder="pol@empresa.io"
                variant="standard"
                fullWidth
                name="email"
                type="email"
                value={formData.email}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <label>
                {t("companyName")} <Asterisk className="asterisk" />
              </label>
              <TextField
                required
                placeholder="LaPieza.io"
                variant="standard"
                fullWidth
                name="companyName"
                value={formData.companyName}
                onChange={onChange}
              />
            </div>
            <div className="field">
              <label>
                {t("phone")} <Asterisk className="asterisk" />
              </label>
              <Stack
                width="100%"
                direction="row"
                alignItems="flex-end"
                spacing={2}
              >
                <Select
                  name="phoneCode"
                  variant="standard"
                  sx={{ flexGrow: 1, height: "100%", fontSize: "1.5rem" }}
                  defaultValue={52}
                  value={formData.phoneCode}
                  onChange={onChange}
                >
                  {countries.map((item) => (
                    <MenuItem
                      sx={{ fontSize: "1.5rem" }}
                      key={item.name}
                      value={item.phoneCode}
                    >
                      {countryToFlag(item?.iso2 || "")}{" "}
                      {`  +${item.phoneCode || ""}`}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  required
                  placeholder={t("phone")}
                  fullWidth
                  variant="standard"
                  name="phone"
                  sx={{ flexGrow: 1.5 }}
                  value={formData.phone}
                  onChange={onChange}
                />
              </Stack>
            </div>
            <div style={{ gridColumn: "1/-1" }} className="field">
              <label>
                {t("helpOption")} <Asterisk className="asterisk" />
              </label>
              <TextField
                required
                placeholder={t("selectOption")}
                fullWidth
                select
                variant="standard"
                name="helpOption"
                value={formData.helpOption}
                onChange={onChange}
              >
                {options.map((o) => (
                  <MenuItem
                    sx={{ fontSize: "1.5rem" }}
                    key={o.value}
                    value={o.value}
                  >
                    {o.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <label>
              {t("howCanWeHelp")} <Asterisk className="asterisk" />
            </label>
            <div className="textareaContainer">
              <textarea
                required
                rows={6}
                cols={50}
                name="body"
                value={formData.body}
                onChange={onChange}
              />
              <label className="counter">{`${counter}/1600`}</label>
            </div>
            <button type="submit">{t("getInTouch")}</button>
          </form>
          {/* <HubspotForm
            portalId="5747853"
            formId="6c938477-f129-4664-8f68-a929ffaa8484"
            onSubmit={() => {}}
            onReady={() => console.log('Form ready!')}
            loading={<div>Loading...</div>}
          /> */}
        </div>
        <StaticImage className="img" src="../images/phone.png" alt="" />
      </section>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Layout>
  );
};

export default styled(Contact)`
  //hs styles
  height: fit-content;

  // normal styles
  padding: 3% 0 2% 10%;
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 45% 55%;
  // height: 100vh;
  @media (max-width: 1200px) {
    grid-template-columns: 50% 50%;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    padding: 8% 10%;
    height: fit-content !important;
  }
  // normal style
  /*@media (max-height: 899px) {
    height: 100vh;
  }*/
  @media (min-height: 900px) {
    // normal
    //height: 100rem;
    // hs
    height: 110rem;
  }
  .notification {
    font-size: 1.5rem;
  }
  .contact {
    ${getFlex("column", "st", "st")};
    row-gap: 2rem;
    h2 {
      font-size: 4.3rem;
      text-align: left;
      @media (max-width: 900px) {
        text-align: center;
      }
    }
    p {
      font-size: 2rem;
      font-family: SofiaProLightAz, sans-serif;
      text-align: left;
      @media (max-width: 900px) {
        text-align: center;
      }
    }
    .form {
      margin-top: 2rem;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      justify-items: flex-start;
      grid-column-gap: 6rem;
      grid-row-gap: 4rem;
      .field {
        width: 100%;
        ${getFlex("column", "sb", "st")};
      }
      .MuiFormControl-root {
        .MuiInput-root {
          font-size: 1.5rem;
        }
      }
    }
    label {
      font-size: 1.6rem;
      color: ${palette.black};
      .asterisk {
        width: 0.6rem;
        color: ${palette.red};
        margin-left: 2rem;
        margin-top: -1rem;
      }
    }
    .textareaContainer {
      position: relative;
      grid-column: 1/-1;
      width: 100%;
      textarea {
        margin-top: -2rem;
        padding: 1rem 1rem 2rem 1rem;
        width: 100%;
        resize: none;
        height: 12rem;
        font-size: 1.6rem;
        border-radius: 4px;
        border: solid #d1d1d1 1px;
      }
      .counter {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0 1rem 1rem 0;
        color: #a5a5b8;
      }
    }
    button {
      grid-column: 1/-1;
      ${buttonRouge};
      justify-self: center;
    }
  }
  .img {
    @media (max-width: 900px) {
      display: none;
    }
  }
`;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
